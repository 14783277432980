(function ($) {

  $(document).ready(function () {

    // ********** jQuery plugins ************ 
    
    // $('body').bobFormContact();

    // $('body').bobFormVoucher();

    $('body').scrollToAnchors();

    // $('#bottom').bottomize();

    $('.glyphicons').glyphClone();

    $('.passage a[title^=button], .footer a[title^=button]').mdLinkToButton();

    $('.footer img').mdImageSize();

    $('body').bobButton();

    $('.body-content').linkTap();

    $(window).scrollTopArrow();

    $('body').fadePage();

    $('.bob-frame-show').bobframe();

  });

}(jQuery));
